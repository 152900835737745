/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import '../views/templates/default-theme/assets/sass/preload-app.scss';


import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import lodash from 'lodash';
window._ = lodash;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";
window.axios = axios;
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};


/*
* Import Vue
*/
import Vue from 'vue';


/*
* Only import needed BootstrapVue Plugins
*/
import {CardPlugin, FormRatingPlugin, ModalPlugin, TabsPlugin, TooltipPlugin} from 'bootstrap-vue'


/*
* Import VueRouter
*/
import VueRouter from 'vue-router';

/*
* Import Vue LazyLoad
*/
import VueLazyload from 'vue-lazyload'
window.Vue = Vue;

/*
* Module variables
* Is module enabled yes or no?
*/
var HAS_PRICETIER = true;
var HAS_COOLBLUE = false;
var HAS_PRODUCTBUNDLES = process.env.MIX_HAS_PRODUCTBUNDLES;
var HAS_BACKINSTOCK_NOTIFICATIONS = process.env.MIX_HAS_BACKINSTOCK_NOTIFICATIONS;
var HAS_DELIVERYDATE_PICKER = process.env.MIX_HAS_DELIVERYDATE_PICKER;
window.HAS_BACKINSTOCK_NOTIFICATIONS = process.env.MIX_HAS_BACKINSTOCK_NOTIFICATIONS;
window.HAS_DELIVERYDATE_PICKER = process.env.MIX_HAS_DELIVERYDATE_PICKER;


/*
*   Application requirements/dependencies
*/

import vue_moment from 'vue-moment';
Vue.use(vue_moment);

import vue_cookies from 'vue-cookies';
Vue.use(vue_cookies);


import 'bootstrap';
import 'readmore-js';
import 'jquery-hoverintent';
import 'slick-carousel';


Vue.use(ModalPlugin)
Vue.use(CardPlugin)
Vue.use(TabsPlugin)
Vue.use(FormRatingPlugin)
Vue.use(TooltipPlugin)
Vue.use(VueLazyload)
Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
})


/*
* Import theme usability/ux scripts
*/
import( '../views/templates/default-theme/assets/js/usability');

Vue.use(VueRouter);
Vue.component('checkout-steps-component', () => import( '../views/templates/default-theme/assets/js/views/checkout/components/CheckoutStepsComponent.vue'));

let routes = [
    //{ path: '/login', name: 'inloggen', component: require('../views/templates/default-theme/assets/js/views/checkout/LoginComponent.vue').default },
    {
        path: '/checkout/details',
        name: 'gegevens',
        component: () => import('../views/templates/default-theme/assets/js/views/checkout/AddressInformation.vue'),
        props:true
    },
    {
        path: '/checkout/shipping',
        name: 'verzending',
        component: () => import('../views/templates/default-theme/assets/js/views/checkout/DeliveryInformation.vue'),
        props:true
    },
    {
        path: '/checkout/payment',
        name: 'betaalwijze',
        component: () => import('../views/templates/default-theme/assets/js/views/checkout/PaymentComponent.vue'),
        props:true
    },
    {
        path: '/checkout/overview',
        name: 'overzicht',
        component: () => import('../views/templates/default-theme/assets/js/views/checkout/OverviewComponent.vue'),
        props:true
    },
    {
        path: '/checkout/thankyou',
        name: 'bedankt',
        component: () => import('../views/templates/default-theme/assets/js/views/checkout/ThankyouComponent.vue'),
        props:true
    }
];

/* Order processing */
Vue.component('order-processing', () => import( './components/views/checkout/OrderProcessing.vue'));

const router = new VueRouter({
    mode: 'history',
    //base: '/checkout/',
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    },
    routes
})

/* Modules imports */
if(HAS_PRICETIER) {
    Vue.component('price-tiers', () => import('../../Modules/PriceTier/Resources/assets/js/components/PriceComponent.vue'));
}

if(HAS_COOLBLUE) {
    Vue.component('coolblue-blocks', () => import('../../Modules/CoolbluePopup/Resources/assets/js/components/CoolblueBlocks.vue'));
}


/* Product Search */
Vue.component('search-component', () => import( './components/global/SearchComponent.vue'));

/* Popup */
Vue.component('popup-component', () => import('./components/global/PopupComponent.vue'));

/* Product Category */
Vue.component('products-overview-component', () => import('./components/views/products/OverviewComponent.vue'));

/* Single Product */
Vue.component('single-product-component', () => import('./components/views/products/SingleProductComponent.vue'));
//Vue.component('product-slides', () => import( './components/views/products/ProductSlides.vue'));
Vue.component('product-reviews', () => import('./components/views/products/ProductReviews.vue'));

/* Produt Slides */
Vue.component('product-slides', () => import( '../views/templates/default-theme/assets/js/views/products/ProductSlides.vue'));

/* Last seen Products */
Vue.component('last-seen-products', () => import('../views/templates/default-theme/assets/js/views/products/LastSeenProductsComponent.vue'));

/* Related Products Component */
Vue.component('product-related', () => import('../views/templates/default-theme/assets/js/views/products/RelatedProductsComponent.vue'));

/* Highlighted products */
Vue.component('highlighted-products', () => import( '../views/templates/default-theme/assets/js/views/products/HighlightedProductsComponent.vue'));

/* MiniCart */
Vue.component('mini-cart-component', () => import('./components/global/MiniCartComponent.vue'));
//Vue.component('mini-quotation-component', () => import('./components/global/MiniQuotationComponent.vue'));

/* Cart */
Vue.component('cart-component', () => import('./components/views/cart/CartComponent.vue'));

/* Quote */
//Vue.component('quote-component', () => import('./components/views/quote/QuoteComponent.vue'));

/* Quote Request */
//Vue.component('quote-request-component', () => import( './components/views/quote/QuoteRequestComponent.vue'));
//Vue.component('quote-thankyou', () => import('./components/views/quote/ThankyouComponent.vue'));


/* MyAccount */
//Vue.component('login-component', () => import( './components/views/account/LoginComponent.vue'));
//Vue.component('register-component', () => import('./components/views/account/RegisterComponent.vue'));
Vue.component('accountoverview-component', () => import('./components/views/account/AccountOverviewComponent.vue'));
Vue.component('order-component', () => import( './components/views/account/OrderComponent.vue'));

/*
* Form Component regarding WC Headless
*/
if(process.env.MIX_APP_HEADLESS_TYPE === 'woocommerce') {
    Vue.component('form-component', () => import( './components/views/global/FormComponent.vue'));
}

/*
* Payment links componenet
*/
Vue.component('order-pay-component', () => import('./components/views/global/OrderPayComponent.vue'));

/*
* Newsletter subscribe component
*/
Vue.component('newsletter-subscribe', () => import( '../views/templates/default-theme/assets/js/views/newsletter/subscribe.vue'));

/*
* Add CSRF Token protection to all Axios Get and Post requests
*/
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/*
* TODO Review single page checkout (No priority)
* Single checkout (Needs code reviewing & testing)
* */
//Vue.component('single-checkout-component',  () => import('../views/templates/default-theme/assets/js/views/checkout/single-checkout/CheckoutComponent.vue'));


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const app = new Vue({
    router,
    el: '#app',
    created: function () {
        this.HAS_BACKINSTOCK_NOTIFICATIONS = true;
    }
});
